const WindmillLogoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260" {...props}>
    <path
      fillRule="evenodd"
      d="M110.157 79.153a54.274 54.274 0 0 1 19.003-3.733 133.39 133.39 0 0 0-1.55-7.077 61.28 61.28 0 0 0-21.679 4.847 129.146 129.146 0 0 1 4.226 5.963Zm-41.418 56.605a62.35 62.35 0 0 1-.279-5.896 61.64 61.64 0 0 1 2.147-16.176c2.394.167 4.799.407 7.2.707a54.454 54.454 0 0 0-2.23 15.469c0 1.254.043 2.499.127 3.732a128.646 128.646 0 0 0-6.965 2.164Zm15.2 34.926A61.561 61.561 0 0 1 72.5 151.843a133.7 133.7 0 0 1 6.241-3.647 54.378 54.378 0 0 0 9.569 16.659 128.925 128.925 0 0 0-4.371 5.829ZM116.6 189.96a61.216 61.216 0 0 1-20.212-8.525 135.1 135.1 0 0 1 2.92-6.61 54.174 54.174 0 0 0 17.417 7.849 129.7 129.7 0 0 0-.125 7.286Zm37.646-3.48a61.235 61.235 0 0 1-21.312 4.88 135.495 135.495 0 0 1-1.511-7.067 54.27 54.27 0 0 0 18.648-3.791 129.877 129.877 0 0 0 4.175 5.978Zm28.497-24.801a61.912 61.912 0 0 1-14.439 16.406 135.904 135.904 0 0 1-5.375-4.838 54.73 54.73 0 0 0 12.913-13.955c2.32.876 4.621 1.671 6.901 2.387Zm8.645-36.854c.134 1.661.203 3.341.203 5.037 0 5.816-.806 11.443-2.313 16.776a135.6 135.6 0 0 1-7.201-.752 54.428 54.428 0 0 0 2.396-16.024c0-.974-.025-1.941-.076-2.902a129.809 129.809 0 0 0 6.991-2.135Zm-14.654-35.072a61.488 61.488 0 0 1 11.141 18.996 134.022 134.022 0 0 1-6.267 3.637 54.358 54.358 0 0 0-9.263-16.786 129.573 129.573 0 0 0 4.389-5.847Zm-32.631-19.84a61.248 61.248 0 0 1 20.29 8.861 135.14 135.14 0 0 1-2.92 6.635 54.207 54.207 0 0 0-17.467-8.184c.103-2.48.134-4.92.097-7.312ZM77.078 98.43a61.877 61.877 0 0 1 14.607-16.74c1.84 1.543 3.647 3.15 5.414 4.805a54.743 54.743 0 0 0-13.103 14.272 128.745 128.745 0 0 0-6.918-2.337Z"
      clipRule="evenodd"
      opacity={0.4}
    />
    <path
      fillRule="evenodd"
      d="M82.41 50.055a92.282 92.282 0 0 1 31.408-11.697c-2.425-2.776-4.801-4.558-7.096-5.798a99.45 99.45 0 0 0-31.041 13.301 77.242 77.242 0 0 1 6.73 4.194ZM33.447 155.956A100.123 100.123 0 0 1 30 129.836c0-2.49.091-4.959.27-7.404 1.881-1.779 4.298-3.466 7.655-4.898a93.754 93.754 0 0 0-.808 12.302c0 7.24.829 14.286 2.396 21.049a77.503 77.503 0 0 0-6.067 5.071Zm33.723 51.681a100.525 100.525 0 0 1-22.273-25.264c.513-2.512 1.51-5.258 3.39-8.339a93.289 93.289 0 0 0 20.842 25.96 78.164 78.164 0 0 0-1.959 7.643Zm57.456 22.057a99.482 99.482 0 0 1-32.911-7.449c-1.009-2.332-1.76-5.134-2.01-8.699a92.28 92.28 0 0 0 32.046 8.811 78.557 78.557 0 0 0 2.875 7.337Zm59.437-15.716a99.475 99.475 0 0 1-31.156 13.223c-2.153-1.319-4.372-3.166-6.63-5.903a92.294 92.294 0 0 0 31.151-11.584c2.211 1.578 4.427 3 6.635 4.264Zm39.041-47.575a99.963 99.963 0 0 1-17.619 29.026c-2.514.184-5.39-.022-8.817-.919a93.042 93.042 0 0 0 18.544-27.651 78.789 78.789 0 0 0 7.892-.456Zm3.742-61.586A100.216 100.216 0 0 1 230 129.836c0 2.987-.131 5.943-.387 8.863-1.923 1.648-4.371 3.195-7.685 4.503.629-4.364.955-8.827.955-13.366 0-6.86-.744-13.546-2.155-19.983a78.533 78.533 0 0 0 6.117-5.036Zm-33.28-52.182a100.478 100.478 0 0 1 22.179 25.714c-.549 2.499-1.588 5.23-3.497 8.29a93.235 93.235 0 0 0-20.642-26.317 78.065 78.065 0 0 0 1.96-7.687ZM135.771 30a99.483 99.483 0 0 1 33 7.63c1.084 2.35 1.912 5.185 2.208 8.83a92.283 92.283 0 0 0-32.245-9.101A77.79 77.79 0 0 0 135.771 30ZM36.574 94.102a77.26 77.26 0 0 1 7.903-.561 93 93 0 0 1 18.436-27.943c-3.591-.81-6.558-.845-9.141-.49a99.938 99.938 0 0 0-17.198 28.994Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M93.635 59.28a78.83 78.83 0 0 1 27.949-8.362c-1.192-2.565-2.381-4.793-3.563-6.736A85.896 85.896 0 0 0 87.958 54.25a98.48 98.48 0 0 1 5.677 5.031ZM45.212 146.93a86.88 86.88 0 0 1-1.689-17.093c0-4.937.414-9.777 1.209-14.488 2.205-.523 4.683-.964 7.48-1.306a79.731 79.731 0 0 0-1.571 15.794c0 4.522.378 8.956 1.104 13.271a99.034 99.034 0 0 0-6.533 3.822Zm26.223 46.535a86.763 86.763 0 0 1-18.903-25.153c1.485-1.711 3.235-3.515 5.297-5.425a79.548 79.548 0 0 0 16.664 23.668 99.26 99.26 0 0 0-3.058 6.91Zm48.369 22.254a85.877 85.877 0 0 1-29.973-9.281c.21-2.256.576-4.741 1.132-7.49a78.822 78.822 0 0 0 27.275 9.383 99.694 99.694 0 0 0 1.566 7.388Zm52.149-10.245a85.883 85.883 0 0 1-29.71 9.98c-1.148-1.955-2.303-4.186-3.46-6.738a78.82 78.82 0 0 0 27.565-8.314 100.38 100.38 0 0 0 5.605 5.072Zm36.321-38.827a86.695 86.695 0 0 1-18.297 25.488c-2.077-.912-4.321-2.042-6.756-3.429a79.64 79.64 0 0 0 17.522-22.868c2.563.372 5.076.641 7.531.809Zm6.715-52.867a86.952 86.952 0 0 1 1.488 16.057c0 5.256-.469 10.402-1.367 15.4-2.218.488-4.701.897-7.491 1.209a79.712 79.712 0 0 0 1.74-16.609c0-4.172-.321-8.269-.942-12.267a99.802 99.802 0 0 0 6.572-3.79Zm-25.698-46.894a86.743 86.743 0 0 1 18.656 25.455c-1.501 1.708-3.266 3.508-5.34 5.413a79.512 79.512 0 0 0-16.386-23.926 98.992 98.992 0 0 0 3.07-6.942ZM140.743 44.02a85.9 85.9 0 0 1 30.167 9.609c-.198 2.266-.556 4.766-1.106 7.537a78.836 78.836 0 0 0-27.44-9.732 98.833 98.833 0 0 0-1.621-7.414ZM51.424 93.674c2.458.142 4.976.385 7.545.733A79.632 79.632 0 0 1 76.567 71.16c-2.47-1.368-4.74-2.467-6.836-3.339a86.688 86.688 0 0 0-18.307 25.853Z"
      clipRule="evenodd"
      opacity={0.8}
    />
    <path
      fillRule="evenodd"
      d="M102.728 69.115a66.191 66.191 0 0 1 23.535-5.724 96.428 96.428 0 0 0-2.351-6.972 73.212 73.212 0 0 0-26.067 7.121 114.983 114.983 0 0 1 4.883 5.575Zm-45.637 71.32c-.498-3.461-.757-7-.757-10.599 0-5.605.627-11.064 1.812-16.31a96.236 96.236 0 0 1 7.341-.086 66.682 66.682 0 0 0-2.035 16.396c0 2.603.15 5.17.44 7.695a115.52 115.52 0 0 0-6.8 2.904Zm20.167 40.83a73.777 73.777 0 0 1-14.926-22.267 97.38 97.38 0 0 1 5.893-4.365 66.575 66.575 0 0 0 12.84 20.304 116.143 116.143 0 0 0-3.806 6.328Zm40.126 21.161a73.197 73.197 0 0 1-25.041-9.264 98.058 98.058 0 0 1 2.224-6.985 66.156 66.156 0 0 0 22.197 8.891c.132 2.51.341 4.964.62 7.358Zm44.807-6.311a73.196 73.196 0 0 1-25.673 7.102 98.863 98.863 0 0 1-2.287-6.965 66.176 66.176 0 0 0 23.138-5.738c1.58 1.957 3.19 3.824 4.822 5.601Zm32.659-31.304a73.984 73.984 0 0 1-16.69 20.769 98.964 98.964 0 0 1-5.94-4.301 66.878 66.878 0 0 0 15.425-18.169c2.433.656 4.837 1.222 7.205 1.701Zm8.193-44.595c.411 3.149.622 6.36.622 9.62a73.85 73.85 0 0 1-1.986 17.063c-2.416.1-4.869.102-7.344.019a66.629 66.629 0 0 0 2.213-17.082c0-2.277-.114-4.528-.338-6.747a115.909 115.909 0 0 0 6.833-2.873Zm-19.6-41.08a73.741 73.741 0 0 1 14.63 22.495 98.296 98.296 0 0 1-5.927 4.352 66.557 66.557 0 0 0-12.527-20.494c1.37-2.12 2.644-4.24 3.824-6.352ZM143.256 57.36a73.197 73.197 0 0 1 25.179 9.62 97.36 97.36 0 0 1-2.216 7.018 66.175 66.175 0 0 0-22.302-9.252c-.145-2.52-.367-4.984-.661-7.386ZM64.874 95.378A73.966 73.966 0 0 1 81.68 74.231a96.295 96.295 0 0 1 5.999 4.246 66.865 66.865 0 0 0-15.585 18.54 115.18 115.18 0 0 0-7.22-1.639Z"
      clipRule="evenodd"
      opacity={0.6}
    />
  </svg>
);
export default WindmillLogoIcon;
